* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  line-height: 1.6;
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
}
